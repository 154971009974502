@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/a10ac2f0e4a8a8c2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/728fab64fe268981-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/c825a19aa942244d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/a1205f1ad7198751-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/fb2601f344c91dc8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/fc62f77911d5a25a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/82a0015457d4e374-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/fa270c6b933293f0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/17e958f604692fe1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/506be5f6b6016e46-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/e7ddc66a52c4c609-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/9e474f77aaf8f947-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/e146d85ff3da8a3c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/f73b3deae998b373-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/506fe7edcf4bb3d2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__fontUnivia_86d025';
src: url(/_next/static/media/8cd0c4fe4ecdf4ec-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__fontUnivia_Fallback_86d025';src: local("Arial");ascent-override: 78.23%;descent-override: 19.56%;line-gap-override: 19.56%;size-adjust: 102.27%
}.__className_86d025 {font-family: '__fontUnivia_86d025', '__fontUnivia_Fallback_86d025'
}.__variable_86d025 {--font-univia: '__fontUnivia_86d025', '__fontUnivia_Fallback_86d025'
}

